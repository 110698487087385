<script setup>

</script>

<template>
  <div class="w-full h-full absolute top-0 left-0">
    <DashBoardMenu class="h-full fixed float-left sidemenu"/>
    <main class=" overflow-auto relative h-full float-right main-content">
        <slot/>
    </main>
  </div>
</template>

<style scoped>
*, html {
  font-family: 'DM Sans', sans-serif ;
}

.sidemenu {
  width: 24rem;
}

.main-content {
  width: calc(100% - 24rem);
}
</style>

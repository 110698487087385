<script setup lang="ts">
const user = useSupabaseUser();
const { auth } = useSupabaseClient();

const links = [
  {
    label: "Accueil",
    to: "/",
  },
  {
    label: "Prestataires",
    to: "/dashboard/prestataire",
  },
];

const logout = async () => {
  await auth.signOut();
  await navigateTo("/login");
};
</script>

<template>
  <section class="relative p-4 h-screen border-r-[1px] fixed">
    <img src="/logo.svg" class="logo mb-4" alt="Sweep Logo" />
    <UVerticalNavigation  :links="links" />
    <div class="absolute w-full right-0 bottom-0 flex border-t-[1px] p-4">
      <div class="w-5/6">
        <p class="text-sm font-medium text-gray-500">{{ user?.email }}</p>
      </div>
      <div class="flex items-center">
        <UIcon
          class="text-gray-500 scale-x-[-1] absolute right-0 m-4"
          name="i-jam-log-out"
          @click="logout"
        />
      </div>
    </div>
  </section>
</template>

<style scoped>
.logo {
  height: 21.53px;
  width: 125px;
}
</style>
